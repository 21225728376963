.banner-wrapper {
	position: fixed;
	width: 100%;
	bottom: 0;
	overflow: hidden;
	pointer-events: none;
	transform: translateY(0);
	transition: transform 1000ms;
}

.banner {
	border-radius: 0.25rem;
	pointer-events: auto;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	box-shadow: 0 0.25rem 0.25rem var(--theme-shadow);
}