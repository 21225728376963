.input {
	display: flex;
	width: 100%;
	color: white;
	border-radius: 0.25rem;
	background: var(--theme-secondary);
	border: 1px solid var(--theme-border);
	font-size: 1.5rem;
}
.input input {
	width: 100%;
	border: 0px;
	background: transparent;
	font-size: 1.5rem;
}
.input input:focus {
	outline-width: 0;
}