.toggleswitch {
	position: relative;
	display: inline-block;
	height: 1.75rem;
	width: 3.25rem;
	border-radius: 1rem;
	transition: background 0.2s;
	cursor: pointer;
	border: 1px solid var(--theme-border);
}

.toggleswitch input {
	position: absolute;
	opacity: 0;
	width: 0;
	height: 0;
}

.toggleswitch-handle {
	background: white;
	border-radius: 50%;
	height: 1.25rem;
	width: 1.25rem;
	margin-top: 0.25rem;
	transition: margin-left 0.2s;
}

.toggleswitch-handle-off {
	margin-left: 0.25rem;
}

.toggleswitch-handle-on {
	margin-left: 1.75rem;
}
