.stattable {
	width: 100%;
	border-collapse: collapse;
}

.stattable th, .stattable td {
	text-align: left;
	vertical-align: bottom;
}

.stattable-compact th, .stattable-compact td { padding: 0.25rem; }
.stattable-comfortable th, .stattable-comfortable td { padding: 0.5rem; }

.stattable th {
	background: var(--theme-secondary);
	padding-bottom: 0.5rem;
}

.stattable tr:nth-child(odd) {
	background: var(--theme-primary);
}